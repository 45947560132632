export default {
    name: "NewChangeClause",
    data() {
        return {
            loading: true
        };
    },
    methods: {},
    props: ["ruleText", "luggage"],
    created() {}
};