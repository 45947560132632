export default {
    name: 'ChangClause',
    data () {
        return {}
    },
    props: {
        ruleText: {
            type: Object,
            default: () => {}
        },
        showProductRule: {
            type: Boolean,
            default: true
        },
        flightType: {
            type: Number,
            default: 1,
        },
        showLuggage: {
            type: Boolean,
            default: true,
        },
    },
    components: {},
    created () {},
    mounted () {},
    activated () {
    },
    deactivated() {},
    destroyed () {},
    watch: {},
    computed: {},
    filters: {
        filterTicketLimitTime(val) {
            let h = 0;
            let m = 0;
            let text = `${h}小时${m}分钟`
            if (val) {
                // 转换为时分
                text = '';
                h = Math.floor(val / 60);
                h = h > 0 ? h : 0;
                m = val % 60;
                if (h > 0) {
                    text = `${h}小时`;
                }
                if (m > 0) {
                    text += `${m}分钟`;
                }
            }
            return text
        }
    },
    methods: {
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
