//  consumer_flight_order_offers
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://api-alpha.wholeok.com/doc.html#/yinzhilv-tmc/%E6%9C%BA%E7%A5%A8%20-%20%E9%A2%84%E5%AE%9A,%E6%94%B9%E7%AD%BE/budgetOrdersUsingPOST
const consumer_flight_order_offers = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/flight/order/offers',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_flight_order_offers;