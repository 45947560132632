export default {
    name: 'travelPolicyTips',
    data () {
        return {}
    },
    props: {
        myTravelPolicy: {
            type: Object,
            default: () => {},
        }
    },
    components: {},
    created () {},
    mounted () {
        console.log(JSON.stringify(this.myTravelPolicy))
    },
    activated () {
    },
    deactivated() {},
    destroyed () {},
    watch: {},
    computed: {},
    filters: {},
    methods: {
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/