// 退改规则
import wrap_cache from 'nllib/src/promise-helper/wrap_cache/1.0.1';
import Q from 'q'
//const Q = require(`q`);
import stipulatesRule from '@/lib/data-service/haolv-default/consumer_flight_search_stipulatesRule'

const consumer_flight_search_stipulatesRule = function (pParameter) {
    if (!pParameter) {
        pParameter = {};
    }
    const clear_cache = pParameter.clear_cache || false;
    const p = pParameter;
    const cache_key = `test-${JSON.stringify(p)}`;
    const fn = stipulatesRule;
    const get_parameter_for_fn = function () {
        return Q.resolve(p);
    };
    return wrap_cache({
        clear_cache,
        cache_key,
        fn,
        get_parameter_for_fn,
    });
}

export default consumer_flight_search_stipulatesRule