export default {
    name: "newLuggageClause",
    data () {
        return {
            loading: true
        }
    },
    props: ["luggageParams"],
    components: {},
    created () {},
    mounted () {},
    activated () {
    },
    deactivated() {},
    destroyed () {},
    watch: {},
    computed: {},
    filters: {},
    methods: {
    }
}