import CitySelector from "yinzhilv-js-sdk/frontend/common-project/component/haolv/domestic-ticket-airport-selector/1.2.2/index.vue";
import CheckTravel from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/check-travel/1.0.0/index.vue'
import EvectionStandard from '@/component/evectionStandard/import_latest_version_module'
import EvectionStandardExceed from '@/component/evectionStandardExceed/import_latest_version_module'
import FlightListSkeleton from '@/component/skeleton/flight-list-skeleton/1.0.0/index.vue'
import NewCalendar from '@/page/admin/travel-book/flight/component/newCalendar/1.0.1/index.vue'
import TravelPolicyTips from '@/page/admin/travel-book/flight/search/component/travelPolicyTips/index.vue'
import NewLuggageClause from "@/page/admin/travel-book/flight/component/newLuggageClause/index.vue";
import NewChangeClause from "@/page/admin/travel-book/flight/component/newChangeClause/index.vue";
import ChangeClausePopup from '@/page/admin/travel-book/flight/component/changeClausePopup/1.0.0/index.vue'
import DomesticTicketAirlineSelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/domestic-ticket-airline-selector/1.0.0/index.vue';
import get_user_info from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info";
import {awaitWrap} from '@/page/admin/company-management/staff-management/common/unit'
import getEvectionAllType from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_evection_logic_judge_option/1.1.1/index'
import getJourneyOptionsCanBeModified from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_journey_options_can_be_modified/1.0.4/index.js'
import consumer_web_policyConfig_getAllowChangeCities from '@/lib/data-service/haolv-default/consumer_web_policyConfig_getAllowChangeCities'
import EpidemicPolicy from '@/page/admin/travel-book/flight/component/epidemicPolicy/1.0.0/index.vue'
import moment from "moment";
import aes_decrypt from 'nllib/src/common/aes/aes_decrypt';
import EvectionInfoTips from '@/component/evection-info-tips/import_latest_version_module'
import PolicyTypeLabel from '@/page/admin/travel-book/flight/component/policyTypeLabel/index.vue'
import TravelPopup from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/travel-popup/1.0.0/index.vue'
// import TravelPopup from '@/component/travel-popup/1.0.0/index.vue'
import EmunSelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/emun-selector/1.0.0/index.vue'

import consumer_flight_search_airlines from '@/lib/data-service/haolv-default/consumer_flight_search_airlines';
import budgetOrder from '@/lib/data-service/haolv-default/consumer_flight_order_budgetOrder'
import consumer_flight_search_flightInfo from '@/lib/data-service/haolv-default/consumer_flight_search_flightInfo'
import consumer_flight_search_flightPrice from '@/lib/data-service/haolv-default/consumer_flight_search_flightPrice'
import consumer_flight_search_cabinDetails from '@/lib/data-service/haolv-default/consumer_flight_search_cabinDetails'
import consumer_flight_search_stipulatesRule from '@/lib/common-service/consumer_flight_search_stipulatesRule'
import consumer_flight_order_personalBudgetOrder from '@/lib/data-service/haolv-default/consumer_flight_order_personalBudgetOrder'
import refund_change_policy_get from '@/lib/common-service/consumer_t-tp-refund-change-policy_get/index'

import consumer_flight_search_stipulates from '@/lib/common-service/consumer_flight_search_stipulates'
import consumer_air_ticket_fligthLuggage from '@/lib/common-service/consumer_air_ticket_fligthLuggage'
import consumer_flight_order_flightsStop from '@/lib/common-service/consumer_flight_order_flightsStop'
import consumer_flight_order_offers from '@/lib/common-service/consumer_flight_order_offers'
import aes_encrypt from "nllib/src/common/aes/aes_encrypt";
import {hidePolicyLabelCompanyIdList, flightListSortByPriceCompanyIdList} from "@/lib/other/index.js";
import consumer_flight_search_queryMeals from "@/lib/data-service/haolv-default/consumer_flight_search_queryMeals";
import consumer_flight_search_queryPlaneNameByCodes from "@/lib/data-service/haolv-default/consumer_flight_search_queryPlaneNameByCodes";

export default {
    data () {
        return {
            flightIndex: 1,

            journeyId: 0,
            evectionNo: '',
            evectionType: '',
            evectionDetailType: '', // '001':因公出差带出差单, '002': 因公出差不带出差单, '101':因私出差

            evectionRequired: 0,
            bookUpperBound: 6,
            unlimitedJourney: 1, // 是否不限行程 0：否 1：是
            allowChangeJourneyTime: 1, // 是否允许修改行程时间 0：否 1：是
            changeEvectionCity: 0, // 是否可选临近城市 0:否, 1:是
            businessRuleControl: 1, // 因公出差规则 1：出差单审批 2：预订审批

            //startDate: '',
            //endDate: '',
            //flightFromStation: {},
            //flightToStation: {},
            depCityPortSearch: {}, // 顶部搜索框显示
            arrCityPortSearch: {}, // 顶部搜索框显示
            depDateSearch: '', // 顶部搜索框显示
            endDateSearch: '', // 顶部搜索框显示

            workTravelType: '',
            journeyType: '',
            orderNo: '',
            userInfo: {}, // 个人信息
            evectionParams: {},
            flightType: 1,
            depDate: '',
            endDate: '',
            depCityPort: {},
            arrCityPort: {},
            orderField: 'depTime', // 排序字段，depTime-起飞时间 arrTime-到达时间。basePrice-价格排序 默认按起飞时间
            orderType: 'asc', // 排序类型，asc-从小到大/从早到晚/从低到高 desc-从大到小/从晚到早/从高到低。默认asc

            startRangeDate: '', // 开始范围
            endRangeDate: '', // 结束范围
            ticketAirlineSelector: null,
            params: {},//预定航班信息
            policyParams: [],

            directFlight: 0, // 是否直飞 0=不限 1=是 2=其它
            hideShareFly: 0, // 是否隐藏共享航班 0 = 不是 1 = 是

            psgType: 1, //  乘机人类型

            // saveWeek: '', //日历active
            startPickerOptions: {
                disabledDate: this.disabledStartDate
            },
            endPickerOptions: {
                disabledDate: this.disabledEndDate
            },
            airlinesList: [],
            airlinesParams: [],
            airlinesParamsInfo: [],
            /*classTypeList: [
                //  舱位列表
                {
                    value: '1,2',
                    label: "头等/商务舱"
                },
                {
                    value: '3',
                    label: "经济舱"
                }
            ],
            cabinParams: '',*/
            takeOffTime: [
                /*{ value: "", label: "全部" },*/
                { value: "00:00-05:59", label: "00:00-05:59" },
                { value: "06:00-11:59", label: "06:00-11:59" },
                { value: "12:00-17:59", label: "12:00-17:59" },
                { value: "18:00-23:59", label: "18:00-23:59" }
            ],
            takeOffTimeParams: [],
            takeOffTimeParamsInfo: [],
            planeSizeList: [
                {value: 1, label: '大型机'},
                {value: 2, label: '中型机'},
                {value: 3, label: '其他型机'},
            ],
            planeSizeParams: [],
            planeSizeParamsInfo: [],
            depAirportList: [],
            depAirportParams: [], // 出发城市机场搜索关键词
            depAirportParamsInfo: [],
            arrAirportList: [],
            arrAirportParams: [],
            arrAirportParamsInfo: [],

            sortList: [
                {label: '出发 早-晚', value: 'depTime-asc'},
                {label: '出发 晚-早', value: 'depTime-desc'},
                {label: '价格 低-高', value: 'basePrice-asc'},
                {label: '价格 高-低', value: 'basePrice-desc'},
            ],
            sortActive: {label: '出发 早-晚', value: 'depTime-asc'},


            airCompanyVisible: false,

            oriFlightsList: [],
            filterFlightList: [], // 中间存储列表
            flightsList: [],

            flightReturnList: [], // 返程航班
            flightReturnIndex: 0, // 往返的当前选择index
            currentPolicyItem: {}, // 暂存选中的policyItem信息
            currentItem: {}, // 暂存选中的航班信息
            returnCheckIndex: 0, // 往返审核的时候的顺序

            loadingShow: true,
            percent: 0,
            percentSetInterval: '',

            luggageParams: {},
            luggage: "",
            luggageLoading: false,

            ruleText: {},
            ruleLoading: false,
            ruleShow: false, // 退改签说明弹窗

            canJourney: true,
            journeyNo: '',

            loades: 10,

            dialogErrorVisible: false,
            dialogError2Visible: false,
            errorText: '',
            returnSecond: 3,
            returnSetInterval: '',

            //query: {},

            searchTemp: '',
            intervalNum: 0,

            cityCanEdit: true,
            timeCanEdit: true,

            journeyDetail: {},
            showEvectionTips: false,
            hidePolicyLabelCompanyIdList: hidePolicyLabelCompanyIdList,
        }
    },
    components: {
        CitySelector,
        NewCalendar,
        DomesticTicketAirlineSelector,
        NewLuggageClause,
        NewChangeClause,
        CheckTravel,
        TravelPolicyTips,
        FlightListSkeleton,
        EvectionStandard,
        EvectionStandardExceed,
        ChangeClausePopup,
        EpidemicPolicy,
        EvectionInfoTips,
        PolicyTypeLabel,
        TravelPopup,
        EmunSelector
    },
    created () {},
    async mounted () {

    },
    async activated () {
        this.$refs['depCityPort'].init({
            placeholder_text: `请选择出发城市`,
        });
        //搜索航班
        let query = this.$route.query;
        const key = 'params';
        const params = JSON.parse(aes_decrypt({
            data: query.params,
            key,
        }));
        //query = Object.assign({}, query, JSON.parse(params))
        //this.query = query
        console.log('11111111111111111111111111111111', params);
        this.journeyId = params.journeyId
        this.evectionNo = query.evectionNo
        this.changeEvectionCity = params.changeEvectionCity
        this.flightType = params.flightType;
        this.flightIndex = params.flightIndex;
        this.evectionType = parseInt(query.evectionType)
        this.journeyType = query.journeyType ? parseInt(query.journeyType) : 0
        this.workTravelType = query.workTravelType ? parseInt(query.workTravelType) : 1
        this.orderNo = query.orderNo ? query.orderNo : ''
        this.evectionRequired = query.evectionRequired === undefined ? 0 : parseInt(query.evectionRequired)
        this.startRangeDate = params.startRangeDate // 开始范围
        this.endRangeDate = params.endRangeDate // 结束范围
        /*this.startDate = params.startDate;
        this.endDate = params.endDate;
        this.flightFromStation = {
            name: params.fromStationName || '广州',
            code: params.fromStationCode || 'CAN'
        };
        this.flightToStation = {
            name: params.toStationName || '北京',
            code: params.toStationCode || 'BJS'
        };*/
        let evectionParams = {
            vm: this,
            situation: '005'
        }
        getEvectionAllType(evectionParams).then(evectionQuery => {
            console.log('22222', evectionQuery);
            this.evectionDetailType = evectionQuery.evectionDetailType
        })
        this.flightType = Number(params.flightType) || 1;//航程类型
        this.depCityPort = {
            name: params.fromStationName || '广州',
            code: params.fromStationCode || 'CAN'
        };
        this.arrCityPort = {
            name: params.toStationName || '北京',
            code: params.toStationCode || 'BJS'
        };
        this.depDate = params.startDate || moment(new Date()).format('YYYY-MM-DD');//去程
        this.endDate = params.endDate || '';//返程
        //this.saveWeek = obj.depDate
        // console.log(this.startRangeDate, this.endRangeDate)
        if (this.flightIndex === 2) {
            this.currentItem = JSON.parse(localStorage.getItem('flightFirstJourney'));
        }
        this.depCityPortSearch = JSON.parse(JSON.stringify(this.depCityPort));
        this.arrCityPortSearch = JSON.parse(JSON.stringify(this.arrCityPort));
        this.depDateSearch = this.depDate;
        this.endDateSearch = this.endDate;
        await this.getUserInfo();
        if(flightListSortByPriceCompanyIdList.indexOf(this.userInfo.companyId) > -1) {
            this.sortActive = {label: '价格 低-高', value: 'basePrice-asc'};
        }
        this.toSearch()

        // -------------
        this.intervalNum = 0
        this.canChangeFactor()
        this.setStandard()
        this.setSearchReact()
        await this.refundChangePolicyGet()
        if (this.changeEvectionCity === 1 && this.journeyId) {
            this.getAllowChangeCity()
        }
    },
    deactivated() {
        clearInterval(this.searchTemp)
    },
    destroyed () {
        clearInterval(this.searchTemp)
    },
    watch: {
        ticketAirlineSelector(newVal, oldVal) {
        },
    },
    computed: {
        filterNum() {
            let setOnlyLineFly = this.directFlight
            let setHideShareFly = this.hideShareFly
            let setTakeOffTimeParamsInfo = this.takeOffTimeParamsInfo.length
            let setDepAirportParamsInfo = this.depAirportParamsInfo.length
            let setArrAirportParamsInfo = this.arrAirportParamsInfo.length
            let setPlaneSizeParamsInfo = this.planeSizeParamsInfo.length
            let setAirlinesParamsInfo = this.airlinesParamsInfo.length
            return setOnlyLineFly + setHideShareFly + setTakeOffTimeParamsInfo + setDepAirportParamsInfo + setArrAirportParamsInfo + setPlaneSizeParamsInfo + setAirlinesParamsInfo
        },
        showCanNotChangeLeft() {
            return ((this.evectionDetailType === '001' || this.evectionDetailType === '003') && this.unlimitedJourney === 0)
            // return !this.cityCanEdit && parseInt(this.journeyId) && (this.changeEvectionCity === 0 || this.journeyDetail.departCityChange === 0)
        },
        showCanNotChangeRight() {
            return ((this.evectionDetailType === '001' || this.evectionDetailType === '003') && this.unlimitedJourney === 0)
            // return !this.cityCanEdit && parseInt(this.journeyId) && (this.changeEvectionCity === 0 || this.journeyDetail.toCityChange === 0)
        },
        showCanNotChangeDate() {
            return ((this.evectionDetailType === '001' || this.evectionDetailType === '003') && this.unlimitedJourney === 0)
            // return !this.timeCanEdit && parseInt(this.journeyId)
        },
    },
    filters: {
        filterPlaneSize(val) {
            if (val === 1) {
                return '大型机'
            } else if (val === 2) {
                return '中型机'
            } else if (val === 3) {
                return '小型机'
            } else {
                return ''
            }
        },
        filterDate(val) {
            return moment(val).format('MM月DD日')
        },
        filterMontyAndDay(val) {
            return moment(val).format('MM-DD')
        },
        filterWeek(val) {
            if (val === '' || !val) {
                return ''
            }
            let day = moment(val).day();
            if (day === 0) {
                return '周日'
            } else if (day === 1) {
                return '周一'
            } else if (day === 2) {
                return '周二'
            } else if (day === 3) {
                return '周三'
            } else if (day === 4) {
                return '周四'
            } else if (day === 5) {
                return '周五'
            } else if (day === 6) {
                return '周六'
            } else {
                return ''
            }
        },
        filterPolicyTips(policyItem) {
            let msg = '';
            if (policyItem.tagProperty) {
                msg = policyItem.tagProperty;
            }
            if (policyItem.tagProperty && policyItem.cabin[0].timeLimitQueue) {
                msg += ' | ';
            }
            if (policyItem.cabin[0].timeLimitQueue) {
                msg += '最晚于起飞前' + policyItem.cabin[0].timeLimitQueue + '出票';
            }
            return msg;
        }
    },
    methods: {
        initEpidemicPolicy() {
            this.$refs['epidemicPolicy'].init({
                startCityCode: this.depCityPort.code,
                endCityCode: this.arrCityPort.code
            })
        },
        async refundChangePolicyGet() {
            let res = await refund_change_policy_get({clear_cache: false});
            this.evectionRequired = res.datas.evectionRequired;
            this.bookUpperBound = res.datas.bookUpperBound;
            this.allowChangeJourneyTime = res.datas.allowChangeJourneyTime;
            this.unlimitedJourney = res.datas.unlimitedJourney;
            this.changeEvectionCity = res.datas.changeEvectionCity;
            this.businessRuleControl = res.datas.businessRuleControl;
        },
        getAllowChangeCity() {
            let _this = this
            consumer_web_policyConfig_getAllowChangeCities({journeyId: this.journeyId}).then(res => {
                this.journeyDetail = res.datas
                if (this.journeyDetail.departCityChange === 1) {
                    this.$nextTick(() => {
                        this.$refs.formEmunSelector.init({
                            type: `100`,
                            request_url: null,
                            entity_define: {
                                property_name_value: 'value',
                                property_name_text: 'text',
                            },
                            get_data_source() {
                                const data = {
                                    entity_list: [],
                                };
                                let list = _this.journeyDetail.departSelectCities
                                data.entity_list = list.map(item => {
                                    return {
                                        value: item.cityCode,
                                        text: `${item.cityName}(${item.cityCode})`
                                    }
                                })
                                return data;
                            },
                            option_all: {
                                is_show: false,
                                text: '全部',
                                value: '',
                            },
                        })
                    })
                }
                if (this.journeyDetail.toCityChange === 1) {
                    this.$nextTick(() => {
                        this.$refs.toEmunSelector.init({
                            type: `100`,
                            request_url: null,
                            entity_define: {
                                property_name_value: 'value',
                                property_name_text: 'text',
                            },
                            get_data_source() {
                                const data = {
                                    entity_list: [],
                                };
                                let list = _this.journeyDetail.toSelectCities
                                data.entity_list = list.map(item => {
                                    return {
                                        value: item.cityCode,
                                        text: `${item.cityName}(${item.cityCode})`
                                    }
                                })
                                return data;
                            },
                            option_all: {
                                is_show: false,
                                text: '全部',
                                value: '',
                            },
                        })
                    })
                }
            })
        },
        canChangeFactor() {
            getJourneyOptionsCanBeModified({
                situation: '200',
                vm: this
            }).then(res => {
                this.cityCanEdit = res.cityCanEdit
                this.timeCanEdit = res.timeCanEdit
            })
        },
        returnFlightParams(item) {
            if (item) {
                return {
                    deptDate: item.deptDate,
                    policyList: [item],
                    evectionNo: this.evectionNo
                }
            } else {
                return ''
            }
        },
        setStandard() {
            if (!this.$refs.standard) {
                setTimeout(() => {
                    this.setStandard()
                }, 500)
                return
            }
            this.$refs.standard.init({
                evectionNo: this.evectionNo,
                businessType: 1,
                evectionRequired: this.evectionRequired,
                businessRuleControl: this.businessRuleControl,
                journeyId: this.journeyId,
            })
        },
        setSearchReact() {
            this.searchTemp = setInterval(() => {
                this.intervalNum++
                if (this.intervalNum >= 30 * 60) {
                    console.log('触发刷新')
                    clearInterval(this.searchTemp)
                    this.$message({
                        duration: 5000,
                        type: "warning",
                        message: '页面停留时间超过30分钟，请您重新搜索航班'
                    })
                    this.search()
                    this.intervalNum = 0
                    this.setSearchReact()
                }
            }, 1000)
        },
        disabledStartDate(time) {
            const currentTime = time.getTime()
            const nowDate = Date.now() - 1000 * 3600 * 24
            const startRangeDate = moment(this.startRangeDate).valueOf()
            const endRangeDate = moment(this.endRangeDate).valueOf()
            console.log(this.evectionDetailType);
            /*if (this.evectionDetailType === '001' && this.flightType === 2) {
                // 因公有出差单 且 往返
                const endDate = moment(this.endDate).valueOf();
                return currentTime < nowDate || currentTime < startRangeDate || currentTime > endRangeDate || currentTime > endDate
            } else if (this.evectionDetailType === '001' && this.flightType === 1) {
                // 因公有出差单 且 单程
                return currentTime < nowDate || currentTime < startRangeDate || currentTime > endRangeDate
            } else if (this.evectionDetailType !== '001' && this.flightType === 2) {
                // 因公没出差单或因私 且 往返
                const endDate = moment(this.endDate).valueOf();
                return currentTime < nowDate || currentTime > endDate;
            } else {
                // 因公没出差单或因私 且 单程
                return currentTime < nowDate;
            }*/
            if (this.evectionDetailType === '001') {
                return currentTime < nowDate || currentTime < startRangeDate || currentTime > endRangeDate
            } else {
                return currentTime < nowDate;
            }
        },
        disabledEndDate(time) {
            const currentTime = time.getTime()
            const nowDate = Date.now() - 1000 * 3600 * 24
            const startRangeDate = moment(this.startRangeDate).valueOf()
            const endRangeDate = moment(this.endRangeDate).valueOf()
            let depDate = ''
            if (this.depDate) {
                depDate = moment(this.depDate).valueOf()
            }

            if (this.evectionDetailType === '001' && depDate !== '') {
                // 因公带出差单 选了出发时间
                return currentTime < nowDate || currentTime < startRangeDate || currentTime > endRangeDate || currentTime < depDate
            } else if (this.evectionDetailType === '001' && depDate === '') {
                // 因公带出差单 没有选出发时间
                return currentTime < nowDate || currentTime < startRangeDate || currentTime > endRangeDate
            } else if (this.evectionDetailType !== '001' && depDate !== '') {
                // 没有出差单 选了出发时间
                return currentTime < nowDate || currentTime < depDate;
            } else {
                // 没有出差单 没选出发时间
                return currentTime < nowDate;
            }
        },
        changeDepDate(val) {
            let startDate = moment(val + ' 00:00:00').valueOf();
            if (this.flightType === 2 ) {
                let endDate = moment(this.endDateSearch + ' 00:00:00').valueOf();
                if (startDate > endDate) {
                    // 打开
                    this.$refs.comeBackDate.focus();
                }
            }
        },
        blurEndDate() {
            let startDate = moment(this.depDateSearch + ' 00:00:00').valueOf();
            let endDate = moment(this.endDateSearch + ' 00:00:00').valueOf();
            if (startDate > endDate) {
                this.depDateSearch = this.depDate
            }
        },
        async getUserInfo() {
            this.loading = true
            let [err, res] = await awaitWrap(get_user_info())
            this.loading = false
            this.userInfo = res.datas
        },

        getAirlines() {
            consumer_flight_search_airlines({
                currentPage: 1,
                pageSize: 9999,
            }).then(res => {
                let airlinesList = res.pageResult.pageData
                airlinesList.forEach(value => {
                    value.label = value.airCompanyShortName
                    value.value = value.airlineCode
                })
                this.airlinesList = airlinesList
            })
        },
        handleCommandSort(val) {
            this.sortActive = val
            let arr = val.value.split('-')
            this.orderField = arr[0]
            this.orderType = arr[1]
            this.setSortList()
        },
        clearAllCommandToSearch() {
            this.airlinesParams = []
            this.airlinesParamsInfo = []
            //this.cabinParams = ''
            this.takeOffTimeParams = []
            this.takeOffTimeParamsInfo = []
            this.planeSizeParams = []
            this.planeSizeParamsInfo = []
            this.depAirportParams = []
            this.depAirportParamsInfo = []
            this.arrAirportParams = []
            this.arrAirportParamsInfo = []
            // this.search()
            this.trainFilter()
        },
        clearAllCommand() {
            this.airlinesParams = []
            this.airlinesParamsInfo = []
            //this.cabinParams = ''
            this.takeOffTimeParams = []
            this.takeOffTimeParamsInfo = []
            this.planeSizeParams = []
            this.planeSizeParamsInfo = []
            this.depAirportParams = []
            this.depAirportParamsInfo = []
            this.arrAirportParams = []
            this.arrAirportParamsInfo = []
        },

        toggleSetTakeOffTimeParams(val) {
            if (this.setBlank(val)) {
                return
            }
            let setIndex = this.takeOffTimeParams.indexOf(val.value)
            if (setIndex > -1) {
                this.takeOffTimeParams.splice(setIndex, 1)
                this.takeOffTimeParamsInfo.splice(setIndex, 1)
            } else {
                this.takeOffTimeParams.push(val.value)
                this.takeOffTimeParamsInfo.push(val)
            }
            this.trainFilter()
        },
        clearSetTakeOffTimeParams() {
            this.takeOffTimeParams = []
            this.takeOffTimeParamsInfo = []
            this.trainFilter()
        },
        toggleSetAirlinesParams(val) {
            let setIndex = this.airlinesParams.indexOf(val.value)
            if (setIndex > -1) {
                this.airlinesParams.splice(setIndex, 1)
                this.airlinesParamsInfo.splice(setIndex, 1)
            } else {
                this.airlinesParams.push(val.value)
                this.airlinesParamsInfo.push(val)
            }
            this.trainFilter()
        },
        clearSetAirlinesParams() {
            this.airlinesParams = []
            this.airlinesParamsInfo = []
            this.trainFilter()
        },
        toggleSetDepAirportParams(val) {
            /*let setIndex = this.depAirportParams.indexOf(val.value)
            if (setIndex > -1) {
                this.depAirportParams.splice(setIndex, 1)
                this.depAirportParamsInfo.splice(setIndex, 1)
            } else {
                this.depAirportParams.push(val.value)
                this.depAirportParamsInfo.push(val)
            }*/
            this.depAirportParams = [val.value]
            this.depAirportParamsInfo = [val]
            this.trainFilter()
        },
        clearSetDepAirportParams() {
            this.depAirportParams = []
            this.depAirportParamsInfo = []
            this.trainFilter()
        },
        toggleSetArrAirportParams(val) {
            /*let setIndex = this.arrAirportParams.indexOf(val.value)
            if (setIndex > -1) {
                this.arrAirportParams.splice(setIndex, 1)
                this.arrAirportParamsInfo.splice(setIndex, 1)
            } else {
                this.arrAirportParams.push(val.value)
                this.arrAirportParamsInfo.push(val)
            }*/
            this.arrAirportParams = [val.value]
            this.arrAirportParamsInfo = [val]
            this.trainFilter()
        },
        clearSetArrAirportParams() {
            this.arrAirportParams = []
            this.arrAirportParamsInfo = []
            this.trainFilter()
        },
        toggleSetPlaneSizeParams(val) {
            /*let setIndex = this.planeSizeParams.indexOf(val.value)
            if (setIndex > -1) {
                this.planeSizeParams.splice(setIndex, 1)
                this.planeSizeParamsInfo.splice(setIndex, 1)
            } else {
                this.planeSizeParams.push(val.value)
                this.planeSizeParamsInfo.push(val)
            }*/
            this.planeSizeParams = [val.value]
            this.planeSizeParamsInfo = [val]
            this.trainFilter()
        },
        clearSetPlaneSizeParams() {
            this.planeSizeParams = []
            this.planeSizeParamsInfo = []
            this.trainFilter()
        },
        clearSetOnlyLineFly() {
            this.directFlight = 0
            this.trainFilter()
        },
        clearSetHideShareFly() {
            this.hideShareFly = 0
            this.trainFilter()
        },
        delSetTakeOffTimeIndex(index) {
            this.takeOffTimeParams.splice(index, 1)
            this.takeOffTimeParamsInfo.splice(index, 1)
            this.trainFilter()
        },
        delSetDepAirportIndex(index) {
            this.depAirportParams.splice(index, 1)
            this.depAirportParamsInfo.splice(index, 1)
            this.trainFilter()
        },
        delSetArrAirportIndex(index) {
            this.arrAirportParams.splice(index, 1)
            this.arrAirportParamsInfo.splice(index, 1)
            this.trainFilter()
        },
        delSetPlaneSizeIndex(index) {
            this.planeSizeParams.splice(index, 1)
            this.planeSizeParamsInfo.splice(index, 1)
            this.trainFilter()
        },
        delSetAirlinesIndex(index) {
            this.airlinesParams.splice(index, 1)
            this.airlinesParamsInfo.splice(index, 1)
            this.trainFilter()
        },

        /*setFlightReturnList() {
            this.flightReturnList = []
            if (this.flightType === 2) {
                let deptJourney = {
                    depCityName: this.depCityPort.name,
                    depCityCode: this.depCityPort.code,
                    arrCityName: this.arrCityPort.name,
                    arrCityCode: this.arrCityPort.code,
                    depDate: this.depDate
                }
                let arrJourney = {
                    depCityName: this.arrCityPort.name,
                    depCityCode: this.arrCityPort.code,
                    arrCityName: this.depCityPort.name,
                    arrCityCode: this.depCityPort.code,
                    depDate: this.endDate
                }
                this.flightReturnList.push(deptJourney)
                this.flightReturnList.push(arrJourney)
            }
        },*/
        clickDate(val) {
            this.flightReturnIndex === 0 ? this.depDate = val : this.endDate = val
            this.search();
        },
        changeDirectFlight(val) {
            this.trainFilter()
        },
        changeFormAndTo() {
            if (this.journeyId !== 0) {
                return
            }
            let depCityPort = JSON.parse(JSON.stringify(this.depCityPortSearch))
            let arrCityPort = JSON.parse(JSON.stringify(this.arrCityPortSearch))
            // this.formData.depCityPort = arrCityPort
            // this.formData.arrCityPort = depCityPort
            //this.$set(this.formData, 'depCityPort', arrCityPort)
            //this.$set(this.formData, 'arrCityPort', depCityPort)
            this.depCityPortSearch = arrCityPort;
            this.arrCityPortSearch = depCityPort;
            // this.handleSearch()
        },
        changeFlight(item, index) {
            // 清空所有搜索条件
            this.clearAllCommand()
            this.flightReturnIndex = index
            if (index === 0) {
                let deptJourney = {
                    depCityName: this.depCityPort.name,
                    depCityCode: this.depCityPort.code,
                    arrCityName: this.arrCityPort.name,
                    arrCityCode: this.arrCityPort.code,
                    depDate: this.depDate
                }
                this.flightReturnList[index] = deptJourney
            } else {
                let arrJourney = {
                    depCityName: this.arrCityPort.name,
                    depCityCode: this.arrCityPort.code,
                    arrCityName: this.depCityPort.name,
                    arrCityCode: this.depCityPort.code,
                    depDate: this.endDate
                }
                this.flightReturnList[index] = arrJourney
            }

            this.search()

        },
        handleSearch() {
            this.showEvectionTips = false
            this.dialogErrorVisible = false
            this.dialogError2Visible = false
            // 清空所有搜索条件
            this.clearAllCommand()
            let cityHasChange = false;
            let startTimeHasChange = false;
            if (this.depCityPortSearch.code !== this.depCityPort.code || this.arrCityPortSearch.code !== this.arrCityPort.code) {
                cityHasChange = true;
            }
            if (this.depDateSearch !== this.depDate) {
                startTimeHasChange = true;
            }
            if (this.flightIndex === 2 && (cityHasChange || startTimeHasChange)) {
                this.$router.go(-1);
                return
            }
            this.depCityPort = JSON.parse(JSON.stringify(this.depCityPortSearch));
            this.arrCityPort = JSON.parse(JSON.stringify(this.arrCityPortSearch));
            this.depDate = this.depDateSearch;
            this.endDate = this.endDateSearch;

            this.search()
        },
        reactSearch() {
            this.showEvectionTips = false
            this.dialogErrorVisible = false
            this.dialogError2Visible = false
            // 清空所有搜索条件
            this.clearAllCommand()
            this.search()
        },
        toSearch() {
            this.showEvectionTips = false
            this.dialogErrorVisible = false
            this.dialogError2Visible = false
            // 清空所有搜索条件
            this.clearAllCommand()
            // 重构往返订单的结果列表
            // this.setFlightReturnList()
            this.search()
        },
        search() {
            //let formData = this.formData
            /*let detection = (obj, rule) => {
                let state = {
                    rule: rule || ['depDate'],
                    inputRule: ['depCityPort', 'arrCityPort'],
                    status: false
                }
                state.status = state.rule.some((name, index) => {
                    if (!obj[state.inputRule[index]]) {
                        if (this.$refs[state.inputRule[index]]) {
                            this.$refs[state.inputRule[index]].$refs.aBaseEntitySelector.$refs.aInput.$refs.input.click()
                        }
                        return true
                    }
                    if (!obj[name]) {
                        this.$refs[name] ? this.$refs[name].focus() : ''
                        return true
                    }
                })
                return state.status
            }

            if (this.flightType === 1) {
                if (detection(formData)) return
            } else {
                let arr = ['depDate', 'comeBackDate']
                if (detection(formData, arr)) return
            }*/
            // this.flightReturnIndex === 0 ? this.saveWeek = this.formData.depDate : this.saveWeek = this.formData.comeBackDate

            // 获取出发城市机场的三字码
            let depCityPortCode = this.flightIndex === 1 ? this.depCityPort.code : this.arrCityPort.code

            // 获取到达地城市机场的三字码
            let arrCityPortCode = this.flightIndex === 1 ? this.arrCityPort.code : this.depCityPort.code

            let params = {
                // 航程类型
                flightType: this.flightType,
                needPnr: 0,
                // 出发城市三字码。
                depCityPortCode: this.depCityPort.code,
                // 到达城市三字码。
                arrCityPortCode: this.arrCityPort.code,
                // 出发日期
                depDate: this.flightIndex === 2 ? this.endDate : this.depDate,
                // 仓位等级 0=不限 1=头等舱 2=公务舱 3=经济舱。
                cabinRank: '',
                // 航空公司二字码
                airLineCode: '',
                // psgType
                psgType: this.psgType,
                //  是否直飞
                directFlight: this.directFlight,
                // 折扣
                discount: 0,
                orderField: '', // this.formData.orderField
                orderType: '', // this.formData.orderType
                takeOffInterval: '',
                flightNo: '',
                planeSize: '',

                'tripDepDate': this.flightIndex === 2 ? this.currentItem['depDate'] : '',
                'tripDepTime': this.flightIndex === 2 ? this.currentItem['depTime'] : '',
            };

            let data = {
                companyCode: '',
                rqData: Object.assign({}, params),
                sign: '',
                timeStamp: '',
                ignoreKeyAccountCompany: this.evectionType === 1 ? 1 : '',
                //applyId: this.query.applyId ? parseInt(this.query.applyId) : '',
                evectionNo: this.evectionNo
            }
            this.getFlights(data);
            // this.initEpidemicPolicy()
            this.reactAdr() // 替换地址栏
        },
        // 获取航班列表
        getFlights(params) {
            this.percent = 0
            this.loadingShow = true
            this.flightsList = [];
            consumer_flight_search_flightInfo(params).then(data => {
                if (!data.rsData) {
                    this.loadingShow = false
                    return
                }
                let flightsList = data.rsData
                let airlinesCodeList = [] // 航司Code合集
                let airlinesList = [] // 航司合集
                let depAirportCodeList = [] // 起飞机场的code合集
                let depAirportList = [] // 起飞机场的合集
                let arrAirportCodeList = [] // 降落机场的code合集
                let arrAirportList = [] // 降落机场的合集
                flightsList.forEach((value, index) => {
                    if (!value.policys) {
                        value.policys = []
                    }
                    value.showMore = false
                    value.tabActive = 1
                    value.show = 0
                    value.vipShow = 0
                    value.flightsShow = []
                    value.price = 0
                    value.discount = 0
                    value.cabin = ''
                    value.loading = false
                    let productList = [] // 经济舱产品
                    let productVipList = [] // 头等舱产品
                    value.productList = productList
                    value.productVipList = productVipList

                    if (airlinesCodeList.indexOf(value.airCompanyCode) === -1) {
                        airlinesCodeList.push(value.airCompanyCode)
                        airlinesList.push({
                            value: value.airCompanyCode,
                            label: value.airCompanyName
                        })
                    }
                    if (depAirportCodeList.indexOf(value.depAirportCode) === -1) {
                        depAirportCodeList.push(value.depAirportCode)
                        depAirportList.push({
                            value: value.depAirportCode,
                            label: value.depAirportName
                        })
                    }
                    if (arrAirportCodeList.indexOf(value.arrAirportCode) === -1) {
                        arrAirportCodeList.push(value.arrAirportCode)
                        arrAirportList.push({
                            value: value.arrAirportCode,
                            label: value.arrAirportName
                        })
                    }
                })
                this.airlinesList = airlinesList
                this.depAirportList = depAirportList
                this.arrAirportList = arrAirportList
                this.oriFlightsList = flightsList
                // this.flightsList = flightsList
                this.trainFilter()
                //机型描述
                this.queryPlaneNames();
                //餐食
                this.queryMeals();
            }).catch(err => {
                this.loadingShow = false
                clearInterval(this.percentSetInterval)
            });
        },
        trainFilter() {
            let oriFlightsList = JSON.parse(JSON.stringify(this.oriFlightsList))

            if (this.directFlight === 1) {
                let flightItemList = []
                oriFlightsList.forEach(value => {
                    if (value.stopNum === 0) {
                        flightItemList.push(value)
                    }
                })
                oriFlightsList = flightItemList
            }

            if (this.hideShareFly === 1) {
                console.log('jin')
                let flightItemList = []
                oriFlightsList.forEach(value => {
                    console.log(value, value.hasShare);
                    if (value.hasShare !== true) {
                        flightItemList.push(value)
                    }
                })
                oriFlightsList = flightItemList
            }

            if (this.airlinesParams.length > 0) {
                // this.airlinesParams.value
                let flightItemList = []
                oriFlightsList.forEach(value => {
                    if (this.airlinesParams.indexOf(value.airCompanyCode) > -1) {
                        flightItemList.push(value)
                    }
                })
                oriFlightsList = flightItemList
            }

            /*if (this.cabinParams !== '') {
                let flightItemList = []
                let cabinParamsArr = this.cabinParams.value.split(',')
                oriFlightsList.forEach(value => {
                    flightItemList.push(value)
                })
                oriFlightsList = flightItemList
            }*/

            if (this.takeOffTimeParams.length > 0) {
                let flightItemList = []
                let takeOffTimeParamsArr = []
                this.takeOffTimeParams.forEach(value => {
                    let takeOffTimeArr = value.split('-')
                    let leftTime = this.timeToMinute(takeOffTimeArr[0])
                    let rightTime = this.timeToMinute(takeOffTimeArr[1])
                    takeOffTimeParamsArr.push([leftTime, rightTime])
                })
                oriFlightsList.forEach(value => {
                    let depTime = this.timeToMinute(value.depTime)
                    takeOffTimeParamsArr.forEach(value1 => {
                        if (depTime >= value1[0] && depTime <= value1[1]) {
                            flightItemList.push(value)
                        }
                    })
                })
                oriFlightsList = flightItemList
            }

            if (this.planeSizeParams.length > 0) {
                let flightItemList = []
                oriFlightsList.forEach(value => {
                    if (this.planeSizeParams.indexOf(value.planeSize) > -1 ) {
                        flightItemList.push(value)
                    }
                })
                oriFlightsList = flightItemList
            }

            if (this.depAirportParams.length > 0) {
                let flightItemList = []
                oriFlightsList.forEach(value => {
                    if (this.depAirportParams.indexOf(value.depAirportCode) > -1 ) {
                        flightItemList.push(value)
                    }
                })
                oriFlightsList = flightItemList
            }

            if (this.arrAirportParams.length > 0) {
                let flightItemList = []
                oriFlightsList.forEach(value => {
                    if (this.arrAirportParams.indexOf(value.arrAirportCode) > -1 ) {
                        flightItemList.push(value)
                    }
                })
                oriFlightsList = flightItemList
            }
            this.filterFlightList = oriFlightsList
            this.setSortList()
        },
        timeToMinute(val) {
            let timeVal = val.split(':')
            let hour = parseInt(timeVal[0]) * 60
            let minute = parseInt(timeVal[1])
            return hour + minute
        },
        setSortList() {
            let flightList = JSON.parse(JSON.stringify(this.filterFlightList))

            let timeToMinute = function (val) {
                let timeVal = val.split(':')
                let hour = parseInt(timeVal[0]) * 60
                let minute = parseInt(timeVal[1])
                return hour + minute
            }
            // 按出发时间正序排列
            let depTimeCompare = function (x, y) {
                let xFromTime = timeToMinute(x.depTime)
                let yFromTime = timeToMinute(y.depTime)
                if (xFromTime < yFromTime) {
                    return -1;
                } else if (xFromTime > yFromTime) {
                    return 1;
                } else {
                    return 0;
                }
            }
            // 按出发时间反序排列
            let depTimeReverseCompare = function (x, y) {
                let xFromTime = timeToMinute(x.depTime)
                let yFromTime = timeToMinute(y.depTime)
                if (xFromTime < yFromTime) {
                    return 1;
                } else if (xFromTime > yFromTime) {
                    return -1;
                } else {
                    return 0;
                }
            }
            // 按到达时间正序排列
            let arrTimeCompare = function (x, y) {
                let xFromTime = timeToMinute(x.arrTime)
                let yFromTime = timeToMinute(y.arrTime)
                if (xFromTime < yFromTime) {
                    return -1;
                } else if (xFromTime > yFromTime) {
                    return 1;
                } else {
                    return 0;
                }
            }
            // 按到达时间反序排列
            let arrTimeReverseCompare = function (x, y) {
                let xFromTime = timeToMinute(x.arrTime)
                let yFromTime = timeToMinute(y.arrTime)
                if (xFromTime < yFromTime) {
                    return 1;
                } else if (xFromTime > yFromTime) {
                    return -1;
                } else {
                    return 0;
                }
            }
            // 按价格正序排列
            let priceCompare = function (x, y) {
                let xPrice = x.basePrice
                let yPrice = y.basePrice
                if (xPrice < yPrice) {
                    return -1;
                } else if (xPrice > yPrice) {
                    return 1;
                } else {
                    return 0;
                }
            }
            // 按价格反序排序
            let priceReverseCompare = function (x, y) {
                let xPrice = x.basePrice
                let yPrice = y.basePrice
                if (xPrice < yPrice) {
                    return 1;
                } else if (xPrice > yPrice) {
                    return -1;
                } else {
                    return 0;
                }
            }

            if (this.orderField === 'depTime') {
                if (this.orderType === 'asc') {
                    flightList.sort(depTimeCompare)
                } else if (this.orderType === 'desc') {
                    flightList.sort(depTimeReverseCompare)
                }
            } else if (this.orderField === 'arrTime') {
                if (this.orderType === 'asc') {
                    flightList.sort(arrTimeCompare)
                } else if (this.orderType === 'desc') {
                    flightList.sort(arrTimeReverseCompare)
                }
            } else if (this.orderField === 'basePrice') {
                if (this.orderType === 'asc') {
                    flightList.sort(priceCompare)
                } else if (this.orderType === 'desc') {
                    flightList.sort(priceReverseCompare)
                }
            }
            this.loadingShow = false
            this.flightsList = flightList
        },
        toSearchAgain(item, index) {
            // 先清除搜索关键字
        },
        getPrice(val) {
            if (val) {
                return val.toFixed(2);
            } else {
                return 0;
            }
        },
        showYoungOldLabel(policyItem, policyIndex, productListIndex, item, index) {
            let flightsList = JSON.parse(JSON.stringify(this.flightsList))
            let params = {
                "businessExt": policyItem.businessExt,
                "depTime": item.depTime,
                "deptCode": item.depAirportCode,
                "deptDate": item.depDate,
                "destCode": item.arrCityCode,
                "flightNo": item.flightNo,
                "policyId": item.policyId,
                "supplierType": policyItem.productType
            }
            consumer_flight_order_offers(params).then(res => {
                const socpAges = res.socpAges
                let msg = '适用年龄：'
                const l = socpAges.length
                for (let i = 0; i < l; i++) {
                    msg+= `${socpAges.minAge} - ${socpAges.maxAge}`
                    if (i + 1 < l) {
                        msg+= `,`
                    }
                }
                flightsList[index].productList[productListIndex][policyIndex].ageLimitText = msg
                this.flightsList = flightsList
            })
        },
        showPolicyLabel(policyItem, policyIndex, productListIndex, item, index) {

        },
        showChangeClause(policyItem, item) {
            this.ruleText = [];
            let data = {
                rqData: {
                    "arrCityPortCode": item.arrCityCode,
                    "cabinCode": policyItem.cabin[0].cabinCode,
                    "depCityPortCode": item.depCityCode,
                    "depDate": item.depDate,
                    "flightNo": item.flightNo,
                    "flightProductType": policyItem.flightProductType,
                    "flightType": this.flightType,
                    "psgType": this.psgType,
                    extData: policyItem.extData,
                    policyId: policyItem.policyId,
                    businessExt: policyItem.businessExt
                }
            };
            this.ruleLoading = true

            consumer_flight_search_stipulates(data).then(res => {
                this.ruleLoading = false
                if (res.datas.rsData) {
                    let refRule = res.datas.rsData.refRule;
                    let chaRule = res.datas.rsData.chaRule;
                    let refRuleTitArr = []
                    let chaRuleTitArr = []
                    let refRuleArr = []
                    let chaRuleArr = []
                    if (refRule && chaRule) {
                        refRule = refRule.rules
                        chaRule = chaRule.rules
                        refRule.forEach(value => {
                            value.type = "refRule"
                            if (refRuleTitArr.indexOf(value.ruleTime) === -1) {
                                refRuleTitArr.push(value.ruleTime)
                                refRuleArr.push(value)
                            }
                        })
                        chaRule.forEach(value => {
                            value.type = "chaRule"
                            if (chaRuleTitArr.indexOf(value.ruleTime) === -1) {
                                chaRuleTitArr.push(value.ruleTime)
                                chaRuleArr.push(value)
                            }
                        })
                        refRuleArr.sort((a, b) => {
                            return a.serial - b.serial
                        })
                        chaRuleArr.sort((a, b) => {
                            return a.serial - b.serial
                        })
                        // refRule.map(item => (item.type = "refRule"));
                        // chaRule.map(item => (item.type = "chaRule"));
                        this.ruleText = [refRuleArr, chaRuleArr];
                    }
                    this.luggage = res.datas.rsData.luggage
                } else {
                    this.ruleText = [];
                    this.luggage = ''
                }

            }).catch(() => {
                this.ruleLoading = false
            });
        },
        showLuggageClause(policyItem, item) {
            this.luggageParams = {}
            let data = {
                rqData: {
                    "arrCityPortCode": item.arrCityCode,
                    "cabinCode": policyItem.cabin[0].cabinCode,
                    "depCityPortCode": item.depCityCode,
                    "depDate": item.depDate,
                    "flightNo": item.flightNo,
                    "flightProductType": policyItem.flightProductType,
                    "flightType": this.flightType,
                    "psgType": this.psgType,
                    businessExt: policyItem.businessExt,
                    policyId: policyItem.policyId
                }
            };
            this.luggageLoading = true
            consumer_air_ticket_fligthLuggage(data).then(res => {
                this.luggageLoading = false
                if (res.datas && res.datas.rsData) {
                    let rsData = res.datas.rsData
                    this.luggageParams = {
                        flyFree: rsData.flyFree,
                        freeWithYou: rsData.freeWithYou,
                        rests: rsData.rests,
                        luggage: rsData.luggage
                    }
                }
            }).catch(() => {
                this.luggageLoading = false
            })
        },
        timeLongTips(time) {
            return new Promise((resolve, reject) => {
                if (time && time >= 20) {
                    this.$confirm('该订单支付后预计30分钟内出票，是否继续预订？', '温馨提示',{
                        confirmButtonText: '继续预订',
                        cancelButtonText: '取消',
                    })
                        .then(() => {
                            // on confirm
                            resolve(true);
                        })
                        .catch(() => {
                            // on cancel
                            resolve(false);
                        });
                } else {
                    resolve(true)
                }
            })
        },
        async getJourney(policyItem, item) {
            if (!policyItem.bookStatus) {
                this.toEditEvectionInfo('001')
                return
            }
            //无自营价格只能出飞常准携程运价，且该运价出票时限大于20分钟，点击创建订单时，弹出提示
            /*let timeTipsRes = await this.timeLongTips(policyItem.cabin[0].ticketLimitTime);
            if (!timeTipsRes) {
                return;
            }*/
            if (!this.canJourney) {
                return
            }
            this.canJourney = false
            let cabinRank = policyItem.cabin[0].cabinRank
            let formData = {
                journeyId: this.journeyId,
                rqData: {
                    "evectionNo": this.evectionNo,
                    "journeyNo": this.orderNo || this.journeyNo,
                    cabinRank: cabinRank === 0 ? 1 : cabinRank === 3 ? 3 : 2,
                    directFlight: 0,
                    flightType: this.flightType,
                    psgType: this.psgType,
                    segmentList: [],
                    evectionType: this.evectionType
                }
            };
            let segment = {
                'ticketLimitTime': policyItem.cabin[0].ticketLimitTime,
                "airportTax": policyItem.priceDatas[0].airportTax,
                "billingFee": policyItem.priceDatas[0].billingFee,
                "cabinName": policyItem.cabin[0].cabinName,
                "cabinCode": policyItem.cabin[0].cabinCode,
                "cabinRank": policyItem.cabin[0].cabinRank,
                "chdCabinCode": policyItem.cabin[0].chdCabinCode,
                "comment": policyItem.comment,
                "crewType": policyItem.priceDatas[0].crewType,
                "discount": policyItem.cabin[0].discount,
                "fuelTax": policyItem.priceDatas[0].fuelTax,
                "policyId": policyItem.policyId,
                "price": policyItem.priceDatas[0].price,
                "priceTaxes": policyItem.priceDatas[0].priceTaxes,
                "productType": policyItem.productType,
                "segmentType": 1,//去返程
                "settlement": policyItem.priceDatas[0].settlement,
                "settlementPriceTaxes": policyItem.priceDatas[0].settlementPriceTaxes,
                "vtWorkTime": policyItem.vtWorkTime,
                "workTime": policyItem.workTime,
                "depTime": item.depTime,
                "flightProductType": policyItem.flightProductType,
                businessExt: policyItem.businessExt,
                airlineQuota: policyItem.priceDatas[0].airlineQuota,
                priceType: policyItem.priceDatas[0].priceType,
                priceSource: policyItem.priceDatas[0].priceType,
                flightKey: item.flightKey,
                tagProperty: policyItem.tagProperty,
                timeLimitQueue: policyItem.cabin[0].timeLimitQueue,

                "airCompanyCode": item.airCompanyCode,
                "airCompanyName": item.airCompanyName,
                "arrAirportCode": item.arrAirportCode,
                "arrAirportName": item.arrAirportName,
                "arrCityCode": item.arrCityCode,
                "arrCityName": item.arrCityName,
                "arrDate": item.arrDate,
                "arrTerminal": item.arrTerminal,
                "arrTime": item.arrTime,
                "chaRule": item.chaRule,
                "depAirportCode": item.depAirportCode,
                "depAirportName": item.depAirportName,
                "depCityCode": item.depCityCode,
                "depCityName": item.depCityName,
                "depDate": item.depDate,
                "depTerminal": item.depTerminal,
                "directFlight": item.directFlight,
                "duration": item.duration,
                "flightNo": item.flightNo,
                "planeType": item.planeType,
                "refRule": item.refRule,
                "stopNum": item.stopNum,

                policyType: policyItem.policyType,
                hasShare: item.hasShare,
                isShare: item.hasShare ? 1 : 0, // 是否共享航班 1-是 0-否"
                sharedFlightNo: item.sharedFlightNo,
                sharedAirCompanyCode: item.sharedAirCompanyCode,
                sharedAirCompanyName: item.sharedAirCompanyName,
                isShareText: `实际乘坐航班：${item.sharedAirCompanyName}${item.sharedFlightNo}请按实际乘坐航班办理值机。`

            }
            /**单程 */
            segment.segmentType = 1
            formData.rqData.segmentList.push(Object.assign({}, segment))
            this.params = formData
            this.policyParams = [policyItem]
            if (this.evectionType === 1) {
                // 因私出差
                this.params.rqData.overproofApplyList = []
                // this.reserveOrder()
                this.reservePersonOrder()
            } else {
                // 因公
                let loading = '';
                this.$refs.checkTravelBox.check({
                    businessType: 1,
                    businessParams: {
                        evectionNo: this.evectionNo,
                        policyList: [policyItem],
                        deptDate: item.depDate,
                    },
                    flightType: this.flightType,
                    depFlightCity: this.depCityPort['name'],
                    arrFlightCity: this.arrCityPort['name'],
                }).then(res => {
                    loading = this.$loading({
                        lock: true,
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    this.params.rqData.overproofApplyList = res.policies
                    budgetOrder(this.params).then(res => {
                        loading.close();
                        this.canJourney = true
                        this.$router.push({
                            name: 'admin-flight-reserve',
                            query: {
                                evectionNo: this.evectionNo || res.datas.rsData.evectionNo,
                                orderNo: res.datas.rsData.orderNo,
                                redirectType: '001',
                                ticketLimitTime: res.datas.rsData.ticketLimitTime,
                            }
                        })
                    }, error => {
                        loading.close();
                        this.canJourney = true
                        // 根据code返回弹窗信息
                        this.setErrorDialog(error.data)
                    }).catch(() => {
                        this.canJourney = true
                        loading.close();
                    })
                }).catch(error => {
                    this.canJourney = true
                })
            }
        },

        // 往返预定
        async returnJourney(policyItem, item) {
            if (!policyItem.bookStatus) {
                this.toEditEvectionInfo('001')
                return
            }
            console.log('111');
            if (!this.canJourney) {
                return
            }
            this.canJourney = false

            //this.currentPolicyItem  = policyItem
            //this.currentItem = item
            //this.flightReturnList[this.flightReturnIndex].flight = JSON.parse(JSON.stringify(this.currentItem))
            let cabinRank = policyItem.cabin[0].cabinRank
            let formData = {
                journeyId: this.journeyId,
                rqData: {
                    "evectionNo": this.evectionNo,
                    "journeyNo": this.orderNo || this.journeyNo,
                    cabinRank: cabinRank === 0 ? 1 : cabinRank === 3 ? 3 : 2,
                    directFlight: 0,
                    flightType: this.flightType,
                    psgType: this.psgType,
                    segmentList: [],
                    evectionType: this.evectionType
                }
            };
            let segment = {
                'ticketLimitTime': policyItem.cabin[0].ticketLimitTime,
                "airportTax": policyItem.priceDatas[0].airportTax,
                "billingFee": policyItem.priceDatas[0].billingFee,
                "cabinCode": policyItem.cabin[0].cabinCode,
                "cabinRank": policyItem.cabin[0].cabinRank,
                "chdCabinCode": policyItem.cabin[0].chdCabinCode,
                "comment": policyItem.comment,
                "crewType": policyItem.priceDatas[0].crewType,
                "discount": policyItem.cabin[0].discount,
                "fuelTax": policyItem.priceDatas[0].fuelTax,
                "policyId": policyItem.policyId,
                "price": policyItem.priceDatas[0].price,
                "priceTaxes": policyItem.priceDatas[0].priceTaxes,
                "productType": policyItem.productType,
                "segmentType": this.flightIndex,//去返程
                "settlement": policyItem.priceDatas[0].settlement,
                "settlementPriceTaxes": policyItem.priceDatas[0].settlementPriceTaxes,
                "vtWorkTime": policyItem.vtWorkTime,
                "workTime": policyItem.workTime,
                "depTime": item.depTime,
                "flightProductType": policyItem.flightProductType,
                businessExt: policyItem.businessExt,
                airlineQuota: policyItem.priceDatas[0].airlineQuota,
                priceType: policyItem.priceDatas[0].priceType,
                priceSource: policyItem.priceDatas[0].priceType,
                flightKey: item.flightKey,
                tagProperty: policyItem.tagProperty,
                timeLimitQueue: policyItem.cabin[0].timeLimitQueue,

                "airCompanyCode": item.airCompanyCode,
                "airCompanyName": item.airCompanyName,
                "arrAirportCode": item.arrAirportCode,
                "arrAirportName": item.arrAirportName,
                "arrCityCode": item.arrCityCode,
                "arrCityName": item.arrCityName,
                "arrDate": item.arrDate,
                "arrTerminal": item.arrTerminal,
                "arrTime": item.arrTime,
                "chaRule": item.chaRule,
                "depAirportCode": item.depAirportCode,
                "depAirportName": item.depAirportName,
                "depCityCode": item.depCityCode,
                "depCityName": item.depCityName,
                "depDate": item.depDate,
                "depTerminal": item.depTerminal,
                "directFlight": item.directFlight,
                "duration": item.duration,
                "flightNo": item.flightNo,
                "planeType": item.planeType,
                "refRule": item.refRule,
                "stopNum": item.stopNum,

                policyType: policyItem.policyType,
                hasShare: item.hasShare,
                isShare: item.hasShare ? 1 : 0, // 是否共享航班 1-是 0-否"
                sharedFlightNo: item.sharedFlightNo,
                sharedAirCompanyCode: item.sharedAirCompanyCode,
                sharedAirCompanyName: item.sharedAirCompanyName,
                isShareText: `实际乘坐航班：${item.sharedAirCompanyName}${item.sharedFlightNo}请按实际乘坐航班办理值机。`
            }
            let checkRes = {};
            if (this.evectionType === 2) {
                console.log('222');
                checkRes = await this.$refs.checkTravelBox.check({
                    businessType: 1,
                    businessParams: {
                        evectionNo: this.evectionNo,
                        policyList: [policyItem],
                        deptDate: item.depDate,
                    },
                    flightType: this.flightType,
                    depFlightCity: this.depCityPort['name'],
                    arrFlightCity: this.arrCityPort['name'],
                })
                console.log('3333', checkRes);
            }

            // 判断如果只选了一程
            this.canJourney = true
            console.log('4444');
            if (this.flightIndex === 2 && this.flightType === 2) {
                console.log('555');
                // 第二程
                formData = JSON.parse(localStorage.getItem('flightReturnJourneyInfo'));
                formData.rqData.segmentList.push(Object.assign({}, segment))
                if (checkRes.policies && checkRes.policies.length > 0) {
                    let policies = checkRes.policies[0];
                    policies['segmentSerial'] = 2;
                    formData.rqData.overproofApplyList.push(policies)
                }
                if (this.evectionType === 1) {
                    // 因私
                    this.params = formData;
                    this.reservePersonOrder();
                } else {
                    console.log('6666');
                    let loading = '';
                    loading = this.$loading({
                        lock: true,
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    budgetOrder(formData).then(res => {
                        loading.close();
                        this.canJourney = true
                        this.$router.push({
                            name: 'admin-flight-reserve',
                            query: {
                                evectionNo: this.evectionNo || res.datas.rsData.evectionNo,
                                orderNo: res.datas.rsData.orderNo,
                                redirectType: '001',
                                ticketLimitTime: res.datas.rsData.ticketLimitTime,
                            }
                        })
                    }, error => {
                        loading.close();
                        this.canJourney = true
                        // 根据code返回弹窗信息
                        this.setErrorDialog(error.data)
                    }).catch(() => {
                        this.canJourney = true
                        loading.close();
                    })
                }
                /*segment.segmentType = 2
                formData.rqData.segmentList.push(Object.assign({}, segment))
                formData.rqData.journeyNo = ''
                // this.params = formData
                this.flightReturnList[this.flightReturnIndex].params = formData
                this.policyParams[this.flightReturnIndex] = policyItem

                if (this.evectionType === 1) {
                    this.toBudgetOrder(0)
                } else {
                    this.checkReturnFlight()
                }*/
            } else {
                console.log('777');
                // 选了第一程
                formData.rqData.segmentList.push(Object.assign({}, segment))
                formData.rqData.overproofApplyList = [];
                if (checkRes.policies && checkRes.policies.length > 0) {
                    let policies = checkRes.policies[0];
                    policies['segmentSerial'] = 1;
                    formData.rqData.overproofApplyList.push(policies)
                }
                // 保存第一程内容到cookie或者localStorge
                this.currentItem = item;
                this.currentItem.policy = policyItem;
                localStorage.setItem('flightFirstJourney', JSON.stringify(this.currentItem));
                localStorage.setItem('flightReturnJourneyInfo', JSON.stringify(formData));
                this.addFlightBack();
            }

        },
        checkReturnFlight() {
            let loading = '';
            this.returnCheckIndex = 0
            this.$refs.checkTravelBox.check({
                businessType: 1,
                businessParams: {
                    evectionNo: this.evectionNo,
                    policyList: [this.policyParams[this.returnCheckIndex]],
                    deptDate: this.flightReturnList[this.returnCheckIndex].params.rqData.segmentList[0].depDate,
                },
                flightType: this.flightType,
                depFlightCity: this.depCityPort['name'],
                arrFlightCity: this.arrCityPort['name'],
            }).then(res => {
                this.flightReturnList[this.returnCheckIndex].params.rqData.overproofApplyList = res.policies
                loading = this.$loading({
                    lock: true,
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                budgetOrder(this.flightReturnList[this.returnCheckIndex].params).then(res => {
                    loading.close();
                    this.canJourney = true
                    if (this.returnCheckIndex === 1) {
                        this.$router.push({
                            name: 'admin-flight-reserve',
                            query: {
                                evectionNo: this.evectionNo || res.datas.rsData.evectionNo,
                                orderNo: res.datas.rsData.orderNo,
                                redirectType: '001'
                            }
                        })
                    } else {
                        this.returnCheckIndex = 1
                        this.checkReturnFlight()
                    }
                }, error => {
                    loading.close();
                    this.canJourney = true
                    // 根据code返回弹窗信息
                    this.setErrorDialog(error.data)
                }).catch(() => {
                    this.canJourney = true
                    loading.close();
                })
            }).catch(error => {
                this.canJourney = true
            })
        },
        toBudgetOrder(index) {
            let loading = ''
            loading = this.$loading({
                lock: true,
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            budgetOrder(this.flightReturnList[index].params).then(res => {
                loading.close()
                this.canJourney = true
                this.evectionNo = res.datas.rsData.evectionNo
                this.journeyNo = res.datas.rsData.orderNo
                if (index === 0) {
                    this.flightReturnList.forEach((value, index1) => {
                        if (index1 === 0) {
                            value.params.rqData.evectionNo = res.datas.rsData.evectionNo
                            value.params.rqData.journeyNo = res.datas.rsData.orderNo
                        } else {
                            value.params.rqData.evectionNo = res.datas.rsData.evectionNo
                        }
                    })
                } else {
                    this.flightReturnList.forEach((value, index1) => {
                        if (index1 !== 0) {
                            value.params.rqData.evectionNo = res.datas.rsData.evectionNo
                            value.params.rqData.journeyNo = res.datas.rsData.orderNo
                        } else {
                            value.params.rqData.evectionNo = res.datas.rsData.evectionNo
                        }
                    })
                }
                if (index === 0) {
                    this.toBudgetOrder(1)
                } else {
                    this.$router.push({
                        name: 'admin-flight-reserve',
                        query: {
                            evectionNo: this.evectionNo || res.datas.rsData.evectionNo,
                            redirectType: '001'
                        }
                    })
                }

            }, error => {
                loading.close()
                // 根据code返回弹窗信息
                this.setErrorDialog(error.data)
                this.canJourney = true
            }).catch(() => {
                loading.close()
                this.canJourney = true
            })
        },
        // 下一程
        addFlightBack() {
            // 翻转出行城市
            let queryParams = {
                fromStationCode: this.arrCityPort.code,
                fromStationName: this.arrCityPort.name,
                toStationCode: this.depCityPort.code,
                toStationName: this.depCityPort.name,
                startDate: this.depDate,
                endDate: this.endDate,
                flightType: this.flightType,
                journeyId: this.journeyId,
                // applyId: this.travelCurrent ? this.travelCurrent.id : '',
                startRangeDate: this.startRangeDate,
                endRangeDate: this.endRangeDate,
                changeEvectionCity: this.changeEvectionCity,
                flightIndex: 2,
            }
            const key = 'params';
            const encrypted = aes_encrypt({
                data: JSON.stringify(queryParams),
                key,
            });
            this.$router.push({
                name: 'admin-flight-search',
                query: {
                    params: encrypted,
                    evectionNo: this.evectionNo,
                    evectionType: this.evectionType, // 1是个人出差，2是出差单出差
                    evectionRequired: this.evectionRequired,
                    workTravelType: this.workTravelType, // 因公出差的类型， 1是有选出差单，2是没有
                    journeyType: this.journeyType, // 0 普通单， 1 不限行程单
                }
            })
        },
        // 根据错误code返回弹窗信息
        setErrorDialog(error) {
            this.errorText = error.msg
            if (error.code === '200028') {
                this.dialogErrorVisible = true;
                this.returnSecond = 3;
                this.returnSetInterval = setInterval(() => {
                    this.returnSecond--;
                    if (this.returnSecond <= 0) {
                        this.search()
                        clearInterval(this.returnSetInterval)
                        this.dialogErrorVisible = false
                    }
                }, 1000)
            } else {
                this.dialogError2Visible = true
            }
        },
        // 因私跳转
        reservePersonOrder() {
            let loading = ''
            loading = this.$loading({
                lock: true,
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            clearInterval(this.searchTemp)
            consumer_flight_order_personalBudgetOrder(this.params).then(res => {
                this.canJourney = true
                loading.close();
                this.$router.push({
                    name: 'admin-personal-flight-reserve',
                    query: {
                        //evectionNo: this.query.evectionNo || res.datas.rsData.evectionNo,
                        orderNo: res.datas.rsData.orderNo,
                        flightType: this.flightType,
                        ticketLimitTime: res.datas.rsData.ticketLimitTime,
                    }
                })
            }, error => {
                loading.close();
                this.canJourney = true
                this.setSearchReact()
                // 根据code返回弹窗信息
                this.setErrorDialog(error.data)
            }).catch(() => {
                this.setSearchReact()
                this.canJourney = true
                loading.close();
            })
        },
        /*reserveOrder() {
            let loading = ''
            loading = this.$loading({
                lock: true,
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            clearInterval(this.searchTemp)
            budgetOrder(this.params).then(res => {
                this.canJourney = true
                loading.close();
                //this.$message({ type: 'success', message: '预定成功！' })
                this.$router.push({
                    name: 'admin-flight-reserve',
                    query: {
                        evectionNo: this.query.evectionNo || res.datas.rsData.evectionNo,
                        orderNo: res.datas.rsData.orderNo,
                        redirectType: '001'
                    }
                })
            }, error => {
                this.setSearchReact()
                loading.close();
                this.canJourney = true
                // 根据code返回弹窗信息
                this.setErrorDialog(error.data)
            }).catch(() => {
                this.setSearchReact()
                this.canJourney = true
                loading.close();
            })
        },*/

        getStopMsg(val, index) {
            let data = {
                "rqData": {
                    "date": val.depDate,
                    "flightNo": val.hasShare ? val.sharedFlightNo : val.flightNo,
                    'depCityCode': val.depCityCode,
                    'arrCityCode': val.arrCityCode,
                    'arrTime': val.arrTime,
                    'deptTime': val.depTime,
                    'depAirCode': val.depAirportCode,
                    'arrAirCode': val.arrAirportCode,
                }
            }
            // val.stopLoad = true
            this.flightsList[index].stopLoad = true
            let flightsList = JSON.parse(JSON.stringify(this.flightsList))
            consumer_flight_order_flightsStop(data).then(res => {
                flightsList[index].stopResponseData = res.datas
                flightsList[index].stopLoad = false
                this.flightsList = flightsList
            }).catch(() => {
                flightsList[index].stopLoad = false
                this.flightsList = flightsList
            })
        },
        reloadItem(item, index) {
            let lowPrice = 9999999;
            item.showMore ? item.showMore = false : item.showMore = true
            item.show = 1
            item.vipShow = 1
            // item.show === 0 ? item.show = 1 : item.show = 0

            if (item.policys && item.policys.length > 0) {
                return;
            }
            item.loading = true
            let params = {
                flightKey: item.flightKey,
                flightNo: item.flightNo,
                //applyId: this.query.applyId ? parseInt(this.query.applyId) : '',
                evectionNo: this.evectionNo,
                accord: item.accord
            }
            consumer_flight_search_cabinDetails(params).then(res => {
                let productList = [] // 经济舱产品
                let productVipList = [] // 头等舱产品
                const cabinParamsArr = [1, 2] // 头等商务舱类型合集
                let data = res.datas
                //data.policys = JSON.parse(JSON.stringify(data.flightPolicyList))
                //data.flightPolicyList = JSON.parse(JSON.stringify(data.policys))
                if (res.datas.accord !== null && res.datas.accord !== undefined) {
                    item.accord = res.datas.accord
                }
                if (data.flightPolicyList && data.flightPolicyList.length > 0) {
                    let arr1 = [] // 经济舱产品
                    let arr2 = [] // 头等舱产品
                    data.flightPolicyList.forEach(value => {
                        if (value.accord === -1) {
                            this.showEvectionTips = true
                        }
                        if(value.priceDatas[0].settlement < lowPrice) {
                            lowPrice = value.priceDatas[0].settlement;
                        }
                        value.ageLimitText = ''
                        value.deptDate = item.depDate
                        if (cabinParamsArr.indexOf(value.cabin[0].cabinRank) >= 0) {
                            arr2.push(value)
                        } else {
                            arr1.push(value)
                        }
                    })
                    if (arr1.length > 0) {
                        productList.push(arr1)
                    }
                    if (arr2.length > 0) {
                        productVipList.push(arr2)
                    }
                }
                if (data.policys && data.policys.length > 0) {
                    let arr1 = [] // 经济舱产品
                    let arr2 = [] // 头等舱产品
                    data.policys.forEach(value => {
                        value.ageLimitText = ''
                        value.deptDate = item.depDate
                        if(value.priceDatas[0].settlement < lowPrice) {
                            lowPrice = value.priceDatas[0].settlement;
                        }
                        if (cabinParamsArr.indexOf(value.cabin[0].cabinRank) >= 0) {
                            arr2.push(value)
                        } else {
                            arr1.push(value)
                        }
                    })
                    if (arr1.length > 0) {
                        productList.push(arr1)
                    }
                    if (arr2.length > 0) {
                        productVipList.push(arr2)
                    }

                }
                item.loading = false
                if (data.flightPolicyList) {
                    item.policys = [...data.flightPolicyList]
                }
                if (data.policys) {
                    item.policys = [...item.policys, ...data.policys]
                }

                item.productList = productList
                item.productVipList = productVipList
                item.show = 1
                item.vipShow = 1
                if (this.flightsList[index].basePrice > lowPrice) {
                    this.flightsList[index].basePrice = lowPrice;
                }
            }, error => {
                item.loading = false
                let errorCode = error.data.rsCode;
                this.errorText = error.data.rsMessage ? error.data.rsMessage : error.data.msg;
                if (errorCode === '090002') {
                    this.dialogErrorVisible = true;
                    this.returnSecond = 3;
                    this.returnSetInterval = setInterval(() => {
                        this.returnSecond--;
                        if (this.returnSecond <= 0) {
                            this.reactSearch()
                            clearInterval(this.returnSetInterval)
                        }
                    }, 1000)
                } else {
                    this.dialogError2Visible = true
                }
            }).catch(() => {
                item.loading = false
            })
        },
        changePolicyTab(item, val) {
            item.tabActive = val
        },
        setLabelColor(val) {
            let className = ''
            if (val === '铨成优享') {
                className = 'orange'
            } else if (val === '平台优选') {
                className = 'red'
            } else if (val === '低价推荐') {
                className = 'blue'
            } else {
                className = 'orange'
            }
            return className
        },
        setTypeLabelColor(val) {
            //"supplier": "1,2,3,4,12,13,9,OPL9,OPL6,OPL11,OPL18,OPL20,OPL16,60,FD,NFD,VARI",
            //"supplierText": "其他渠道(供应商)【平台优选】",
            // "bigCustomers": "QCYX",
            //"bigCustomersText": "企业协议(大客户)",
            //"lowPrice": "YPL1,FPL1,BPL1,OPL4,OPL3,OPL5,OPL25,SPL1,OPL21,OPL15,OPL13,OPL14,OPL34,OPL1,SGL1,OPL26,OPL30",
            //"lowPriceText": "低价推荐",
            // "website": "21,22,23,24,25,31,6,CXF1,WS,GW311,GW312,OPL17,OPL23",
            //"websiteText": "航司官网(官网)",
            //"platformProprietary": "61",
            //"platformProprietaryText": "商旅优选(自营)【商旅推荐】"
            const btoList = ['1','2','3','4','12','13','9','OPL9','OPL6','OPL11','OPL18','OPL20','OPL16','60','FD','NFD','VARI']
            const lprList = ['YPL1','FPL1','BPL1','OPL4','OPL3','OPL5','OPL25','SPL1','OPL21','OPL15','OPL13','OPL14','OPL34','OPL1','SGL1','OPL26','OPL30']
            const owList = ['21','22','23','24','25','31','6','CXF1','WS','GW311','GW312','OPL17','OPL23']
            const qcList = ['QCYX']
            const ptList = ['61']
            if (btoList.indexOf(val) > -1) {
                return 'type7'
            } else if (lprList.indexOf(val) > -1) {
                return 'type5'
            } else if (owList.indexOf(val) > -1) {
                return 'type3'
            } else if (qcList.indexOf(val) > -1) {
                return 'type4'
            } else if (ptList.indexOf(val) > -1) {
                return 'type2'
            } else {
                return 'type6'
            }
        },
        setTypeLabelColorByProductName(val) {
            if (val === '大客户') {
                return 'type7'
            } else if (val === '供应商') {
                return 'type1'
            } else if (val === '铨成优享') {
                return 'type2'
            } else if (val === '低价推荐') {
                return 'type4'
            } else if (val === '航司直航') {
                return 'type3'
            } else {
                return 'type6'
            }
        },
        checkEvectionRequired() {
            this.$message({
                type: "warning",
                message: '不能修改出差单既定行程'
            })
        },
        checkEvectionTimeRequired() {
            this.$message({
                type: "warning",
                message: '不能修改出差单既定行程时间'
            })
        },
        clickShowMore(item) {
            if (item.show === item.productList.length) {
                item.show = 1
                item.showMore = false
            } else {
                item.show++
            }
        },
        clickVipShowMore(item) {
            if (item.vipShow === item.productVipList.length) {
                item.vipShow = 1
                item.showMore = false
            } else {
                item.vipShow++
            }
        },
        showRulePopup(val, item) {
            this.ruleShow = true
            this.ruleLoading = true
            this.ruleText = {}
            let params = {
                rqData: {
                    cabinCode: val.cabin[0].cabinCode,
                    flightKey: item.flightKey,
                    flightNo: item.flightNo,
                    policyId: val.policyId
                }
            }
            consumer_flight_search_stipulatesRule(params).then(res => {
                this.ruleText = res.datas.rsData
            }).finally(() => {
                this.ruleLoading = false
            })
        },
        setBlank(val) {
            const currentDay = moment().format('YYYY-MM-DD')
            const currentTime = moment().valueOf()
            if (currentDay !== this.depDate) {
                return false
            }
            const valTimeArr = val.value.split('-')
            const rightTime = moment(`${currentDay} ${valTimeArr[1]}:59`).valueOf()
            if (currentTime < rightTime) {
                return false
            } else {
                return true
            }
        },
        toEditEvectionInfo(popupType) {
            this.$refs['travelPopup'].init({
                popupType,
                evectionNo: this.evectionNo,
                evectionRequired: this.evectionRequired,
                journeyId: this.journeyId,
                businessRuleControl: this.businessRuleControl,
            })
        },
        updateEvectionInfo(val) {
            this.evectionNo = val.evectionNo
            this.workTravelType = val.workTravelType
            this.journeyType = val.journeyType
            this.journeyId = val.journeyId

            this.startRangeDate = val.travelCurrent ? val.travelCurrent.active.departTime : ''; // 开始范围
            this.endRangeDate = val.travelCurrent ? val.travelCurrent.active.departTimeEnd : ''; // 结束范围
            if (val.travelCurrent) {
                const queryParams = {
                    fromStationCode: val.travelCurrent.active.departCityId || this.depCityPort.code,
                    fromStationName: val.travelCurrent.active.departCityName || this.depCityPort.name,
                    toStationCode: val.travelCurrent.active.toCityId || this.arrCityPort.code,
                    toStationName: val.travelCurrent.active.toCityName || this.arrCityPort.name,
                    startDate: val.travelCurrent.active.tripDate || val.travelCurrent.active.departTime,
                    endDate: val.travelCurrent.active.returnTime || '',
                    flightType: this.flightType,
                    journeyId: val.journeyId,
                    applyId: val.travelCurrent.id,
                    startRangeDate: val.travelCurrent.active.departTime,
                    endRangeDate: val.travelCurrent.active.departTimeEnd,
                    changeEvectionCity: this.changeEvectionCity
                }
                const key = 'params';
                const encrypted = aes_encrypt({
                    data: JSON.stringify(queryParams),
                    key,
                });
                this.flightType = this.flightType;//航程类型
                    this.depCityPort = {
                    name: val.travelCurrent.active.departCityName || this.depCityPort.name,
                        code: val.travelCurrent.active.departCityId || this.depCityPort.code
                };
                this.arrCityPort = {
                    name: val.travelCurrent.active.toCityName || this.arrCityPort.name,
                        code: val.travelCurrent.active.toCityId || this.arrCityPort.code
                };
                this.depDate = val.travelCurrent.active.tripDate || val.travelCurrent.active.departTime;//去程
                    this.endDate = val.travelCurrent.active.returnTime || '';//返程
                //this.formData = Object.assign(this.formData, obj)
                //this.saveWeek = obj.depDate

                // 替换地址栏参数
                let adrParams = {
                    params: encrypted,
                    evectionNo: this.evectionNo,
                    evectionType: this.evectionType, // 1是个人出差，2是出差单出差
                    evectionRequired: this.evectionRequired,
                    workTravelType: this.workTravelType, // 因公出差的类型， 1是有选出差单，2是没有
                    journeyType: this.journeyType,
                }
                let adrParamsText = ''
                for(const i in adrParams) {
                    adrParamsText+=`&${i}=${adrParams[i]}`
                }
                // 组装当前页面url
                let href = location.href.split('?')[0]
                let newUrl = href + '?' + adrParamsText
                history.replaceState(null, null, newUrl)
            }
            let evectionParams = {
                vm: this,
                situation: '005'
            }
            getEvectionAllType(evectionParams).then(evectionQuery => {
                this.evectionDetailType = evectionQuery.evectionDetailType
            })
            console.log('11111');
            if (this.flightIndex === 2) {
                this.reactSearch()
            } else {
                this.toSearch();
            }

            //this.initEpidemicPolicy()
            this.setStandard()
            this.canChangeFactor()
            if (this.changeEvectionCity === 1 && parseInt(this.journeyId)) {
                this.getAllowChangeCity()
            }
        },
        reactAdr() {
            const queryParams = {
                fromStationCode: this.depCityPort.code,
                fromStationName: this.depCityPort.name,
                toStationCode: this.arrCityPort.code,
                toStationName: this.arrCityPort.name,
                startDate: this.depDate,
                endDate: this.endDate,
                flightType: this.flightType,
                journeyId: this.journeyId,
                // applyId: this.applyId,
                startRangeDate: this.startRangeDate,
                endRangeDate: this.endRangeDate,
                changeEvectionCity: this.changeEvectionCity,
                flightIndex: this.flightIndex,
            }
            const key = 'params';
            const encrypted = aes_encrypt({
                data: JSON.stringify(queryParams),
                key,
            });
            // 替换地址栏参数
            let adrParams = {
                params: encrypted,
                evectionNo: this.evectionNo,
                evectionType: this.evectionType, // 1是个人出差，2是出差单出差
                evectionRequired: this.evectionRequired,
                workTravelType: this.workTravelType, // 因公出差的类型， 1是有选出差单，2是没有
                journeyType: this.journeyType,
            }
            let adrParamsText = ''
            for(const i in adrParams) {
                adrParamsText+=`&${i}=${adrParams[i]}`
            }
            // 组装当前页面url
            let href = location.href.split('?')[0]
            let newUrl = href + '?' + adrParamsText
            history.replaceState(null, null, newUrl)
        },
        queryPlaneNames(){
            const planeTypes = this.flightsList.map(e => {
                return e.planeType
            })
            consumer_flight_search_queryPlaneNameByCodes(planeTypes).then(res=>{
                console.log("planeTypes",res);
                let planeMap = new Map();
                if(res.datas){
                    res.datas.forEach(i=>{
                        planeMap.set(i.planeType,i.planeName);
                    })
                }
                this.flightsList.forEach(i=>{
                    let planeName = planeMap.get(i.planeType);
                    i.planeName = planeName? planeName:i.planeType;
                })
                this.$forceUpdate();


            }).catch(err=>{
                console.log('机型查询出错了',err);
            })
        },
        queryMeals() {

            let promises = [];
            for (let i = 0; i < this.flightsList.length; i++) {
                let val = this.flightsList[i];
                let param = {
                    flightNo: val.flightNo,
                    deptTime: val.depTime,
                    arrTime: val.arrTime
                };
                promises.push(consumer_flight_search_queryMeals(param));
            }
            if(promises.length>0){
                Promise.all(promises).then( (posts) =>{
                    for (let i = 0; i < this.flightsList.length; i++) {
                        let meals = posts[i].datas;
                        this.flightsList[i].hasMeals = meals? '有餐食':'无餐食';
                    }
                    // this.detail = detail;
                    this.$forceUpdate();
                }).catch(function(reason){
                    console.log('出错了',reason)
                });
            }
        },

        changeFirst() {
            this.$router.go(-1);
        },
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
