// consumer_flight_search_flightPrice 查询航班报价
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://api-alpha.wholeok.com/doc.html#/yinzhilv-tmc/%E6%9C%BA%E7%A5%A8%20-%20%E6%9F%A5%E8%AF%A2/queryFlightPriceUsingPOST
const consumer_flight_search_flightPrice = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/flight/search/flightPrice',
        data: pParameter,
        enable_error_alert: false // 临时隐藏
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_flight_search_flightPrice;