// consumer_flight_order_flightsStop 查询机票经停信息
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://api-alpha.haolvtrip.com/doc.html#/tmc-biz/%E6%9C%BA%E7%A5%A8%20-%20%E9%A2%84%E5%AE%9A,%E6%94%B9%E7%AD%BE/flightsStopUsingPOST
const consumer_flight_search_queryPlaneNameByCodes = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/flight/search/queryPlaneNameByCodes',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_flight_search_queryPlaneNameByCodes;