// consumer_flight_search_flightInfo 只查询航班信息
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://api-alpha.wholeok.com/doc.html#/yinzhilv-tmc/%E6%9C%BA%E7%A5%A8%20-%20%E6%9F%A5%E8%AF%A2/queryFlightInfoUsingPOST
const consumer_flight_search_flightInfo = (pParameter) => {
    if (!pParameter) pParameter = {};
    if (pParameter.rqData) {
        pParameter.rqData.orderClient = 1
    }
    const p = {
        method: 'post',
        urlSuffix: '/consumer/flight/search/flightInfo',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_flight_search_flightInfo;